     import React from 'react';

import Layout from '../components/layout';
import Slider from '../components/slider';
import { Box, Text, Stack, _hover, Flex } from '@chakra-ui/core';
import PageWrapper from '../components/page.wrapper';

import {
    FaFacebookF,
    FaTwitter,
    FaLinkedinIn,
    FaGithub,
    FaEnvelope,
    FaPhoneAlt,
    FaMapMarkerAlt,
    FaInstagram,
} from 'react-icons/fa';

const img = require('../images/innerbanner.jpg');
const imgNew = require('../images/carehome/prepolicycheckup.jpg');
 


const PrePolicyHealthCheckUp = () => (
    <Layout>
        <Box className="sub-menu">
            <Box className="sub-banner" style={{ backgroundImage: `url(${img})` }} />
            <Box className="content-row">
				<h1>Pre Policy Health CheckUp</h1>
                <Flex align="center" className="flex-row">
                    <Box flex="1" p="4">
                        <Box as="img" src={imgNew} w="100%" />
						 
                    </Box>

                    <Box flex="1" p="4" className="brief-para">
                        <p size="150px">
                           Executive Pre policy Health check up (PPHC) insurances at Customer Door step  for All Insurance                                companies
  
                        </p>
						 
                    </Box>
                </Flex>
            </Box>
        </Box>
    </Layout>
);

export default PrePolicyHealthCheckUp;